import React from 'react';
import { ThemeProvider } from '@mui/material';
import './App.css';
import theme from './theme/Theme';
import PlayerProvider from './contexts/PlayerProvider';
import Routes from './Routes';
import RoomProvider from './contexts/RoomProvider';

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <RoomProvider>
        <PlayerProvider>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </PlayerProvider>
      </RoomProvider>
    </React.StrictMode>
  );
};

export default App;
