import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Outlet } from 'react-router-dom';

const Home: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid container spacing={2} alignItems="center" justifyContent={'center'}>
        <Outlet />
      </Grid>
    </Box>
  );
};
export default Home;

