import React, { useState, createContext } from 'react';

export const RoomContext = createContext<any>(null);

const RoomProvider = ({ children }: { children: React.ReactNode }) => {
  const [room, setRoom] = useState(0);
  const [players, setPlayers] = useState([]);

  return <RoomContext.Provider value={{ room, players, setRoom, setPlayers }}>{children}</RoomContext.Provider>;
};

export default RoomProvider;
