import { useEffect, useState } from 'react';

export const useCountdownTimer = (time: Date) => {
  const [countdown, setCountdown] = useState('00:00:00');
  const [isCountdownFinished, setCountdownFinished] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const distance = time ? time.getTime() - now.getTime() : 0;

      if (distance < 0) {
        setCountdownFinished(true);
        clearInterval(interval);
        setCountdown('00:00:00');
      } else {
        const hours = Math.floor(distance / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setCountdown(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  return { countdown, setCountdown, isCountdownFinished };
};
