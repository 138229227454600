import React, { useContext, useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, Button, Box, Avatar } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { fetchJoinedPlayers } from '../../hooks/useGetJoinedPlayersByRoomId';
import { authenticatePlayerAndProvideEntry } from '../../hooks/useAuthenticatePlayerAndProvideEntry';
import { useCountdownTimer } from '../../hooks/common/useCountdownTimer';
import { RoomContext } from '../../contexts/RoomProvider';

const PlayersLobby = () => {
  const [players, setPlayers] = useState([]);
  const { room, setRoom } = useContext(RoomContext);
  const location = useLocation();
  const { roomId } = useParams();

  const { countdown, isCountdownFinished } = useCountdownTimer(new Date(room.startDateTime));

  useEffect(() => {
    const getJoinedPlayers = async () => {
      const data = await fetchJoinedPlayers(roomId);

      setPlayers(data.players);
      setRoom(data.room);
    };

    getJoinedPlayers();
  }, [roomId]);

  const handleAuthentication = async () => {
    if (!isCountdownFinished) return;
    const token = new URLSearchParams(location.search).get('token');
    const isAuthenticated = await authenticatePlayerAndProvideEntry(roomId, token);
    if (!isAuthenticated) {
      console.log('Failed to authenticate');
    }
  };

  return (
    <Box m={4}>
      <Typography variant="h6" textAlign="center" mb={4}>
        {!!room && isCountdownFinished ? (
          <span style={{ fontWeight: 'bold' }}>{room.name} is Live</span>
        ) : (
          <>
            {room.name} starts in: <span style={{ fontWeight: 'bold' }}>{countdown}</span>
          </>
        )}
      </Typography>
      <Grid container spacing={3}>
        {players.map((player, index) => (
          <Grid item xs={3} key={index}>
            <Card>
              <CardContent
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}
              >
                <Avatar alt={player.fullName} src={player.avatarUrl} />
                <Typography color="textSecondary" variant="h6" gutterBottom>
                  {player.fullName}
                </Typography>
                <Typography color="textSecondary" sx={{ fontWeight: 'bold' }}>
                  ₹{player.availableFunds}
                </Typography>
                {/* Add more player details here */}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="secondary"
        disabled={!isCountdownFinished}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          marginTop: '20px'
        }}
        onClick={handleAuthentication}
      >
        Enter
      </Button>
    </Box>
  );
};

export default PlayersLobby;
