import React from 'react';
import { createChart } from 'lightweight-charts';
import PropTypes from 'prop-types';
import { timeToLocal } from '../../helpers/time';
import { debounce } from 'lodash';

const CandleChart = ({ legend, initCandles, lastCandle, decimals }) => {
  const chartRef = React.useRef(null);
  const legendRef = React.useRef(null);
  const chart = React.useRef(null);
  const candlestickSeries = React.useRef(null);
  const [chartHeight, setChartHeight] = React.useState(window.innerHeight);

  const calculateChartHeight = () => {
    const header = document.querySelector('header');
    const tradingChartHeader = document.querySelector('#trading-chart-header');

    if (header && tradingChartHeader) {
      const headerHeight = header.offsetHeight;
      const tradingChartHeaderHeight = tradingChartHeader.offsetHeight;
      const additionalSpace = 57;
      const totalHeight = headerHeight + tradingChartHeaderHeight + additionalSpace;

      const remainingHeight = window.innerHeight - totalHeight;

      setChartHeight(remainingHeight);
    }
  };

  React.useEffect(() => {
    calculateChartHeight(); // Set initial height

    const handleResize = debounce(() => {
      calculateChartHeight();
    }, 200); // Adjust debounce delay as needed

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (chartRef.current && !chart.current) {
      chart.current = createChart(chartRef.current, {
        width: chartRef.current.offsetWidth,
        height: chartHeight,
        alignLabels: true,
        timeScale: {
          rightOffset: 5,
          barSpacing: 15,
          fixLeftEdge: false,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          borderColor: '#fff000',
          visible: true,
          timeVisible: true,
          secondsVisible: false
        },
        rightPriceScale: {
          scaleMargins: {
            top: 0.3,
            bottom: 0.25
          },
          borderVisible: false
        },
        priceScale: {
          autoScale: true
        },
        watermark: {
          color: 'rgba(0, 0, 0, 0.5)',
          visible: true,
          text: 'TheSiriusAcademy',
          fontSize: 12,
          horzAlign: 'left',
          vertAlign: 'bottom'
        }
      });

      candlestickSeries.current = chart.current.addCandlestickSeries({
        priceScaleId: 'right',
        upColor: '#00AA00',
        downColor: '#AA0000',
        borderVisible: false,
        wickVisible: true,
        borderColor: '#000000',
        wickColor: '#000000',
        borderUpColor: '#00AA00',
        borderDownColor: '#AA0000',
        wickUpColor: '#00AA00',
        wickDownColor: '#AA0000',
        priceFormat: {
          type: 'custom',
          minMove: '0.00000001',
          formatter: (price) => parseFloat(price).toFixed(5)
        }
      });

      candlestickSeries.current.setData(
        initCandles?.map((candle) => {
          const newCandle = { ...candle };
          newCandle.time = timeToLocal(newCandle.time);
          return newCandle;
        })
      );
    }
  }, [initCandles, chartHeight]);

  React.useEffect(() => {
    if (candlestickSeries.current && lastCandle) {
      const newLastCandle = { ...lastCandle };
      newLastCandle.time = timeToLocal(newLastCandle.time);

      if (
        newLastCandle &&
        newLastCandle?.time &&
        newLastCandle?.open &&
        newLastCandle?.high &&
        newLastCandle?.low &&
        newLastCandle?.close &&
        newLastCandle?.time >= initCandles[initCandles.length - 1]?.time
      ) {
        candlestickSeries.current.update(newLastCandle);
      }
    }
  }, [lastCandle, initCandles]);

  React.useEffect(() => {
    let resizeObserver;

    if (chartRef.current) {
      const handleResize = debounce(() => {
        if (chartRef.current) {
          chart.current?.resize(chartRef.current.offsetWidth, chartHeight);
        }
      }, 200); // Adjust debounce delay as needed

      resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(chartRef.current);
    }

    return () => {
      if (resizeObserver && chartRef.current) {
        resizeObserver.unobserve(chartRef.current);
      }
    };
  }, [chartHeight]);

  return (
    <div ref={chartRef} id="chart" style={{ width: '100%' }}>
      <div ref={legendRef}>{legend}</div>
    </div>
  );
};

CandleChart.propTypes = {
  legend: PropTypes.string,
  initCandles: PropTypes.array,
  lastCandle: PropTypes.object,
  decimals: PropTypes.number
};

export default CandleChart;
