import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Paper, Stack, Typography } from '@mui/material';
import Loading from '../loading/Loading';
import axios from 'axios';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '1rem 0.2rem',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  borderBottom: '1px solid #B0A8B9',
  borderRadius: '0px',
  cursor: 'pointer', // Make the entire item clickable
  '&:hover': {
    backgroundColor: theme.palette.action.hover // Optional: Change background on hover
  }
}));

const WatchlistSearchItem = ({ stock, setInputStock, setUserWatchlist, setStocks, onStockAdded }) => {
  const [loading, setLoading] = useState(false);

  const handleAddStockToWatchlist = async () => {
    const data = {
      symbolData: stock
    };
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/twelve-candles/watchlist`, data, {
        withCredentials: true
      });
      if (response.status === 200) {
        if (response?.data?.data) {
          setUserWatchlist((prevData) => [...prevData, response.data.data]);
          setInputStock('');
          setStocks([]);
          alert(response?.data?.message || 'Added to watchlist');

          // Call the callback with the new stock data
          if (onStockAdded) {
            onStockAdded(response.data.data);
          }
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert('Error adding item to watchlist');
    }
  };

  return (
    <Item key={stock._id} onClick={handleAddStockToWatchlist}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack>
          <Typography display="block" gutterBottom sx={{ fontSize: '14px', fontWeight: 'bold' }}>
            {stock.symbol}
          </Typography>
          <Typography sx={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: stock.description }} />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="end">
          <Typography display="block" gutterBottom pr={4} sx={{ fontSize: '14px', textAlign: 'right' }}>
            {stock.exchange}
          </Typography>
          {loading && <Loading />}
        </Stack>
      </Stack>
    </Item>
  );
};

export default WatchlistSearchItem;
