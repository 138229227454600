import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import Home from './pages/home/Home';
import Header from './components/header/Header';
import Orders from './pages/orders/Orders';
import Positions from './pages/positions/Positions';
import LeaderBoard from './pages/leaderboard/LeaderBoard';
import TradingPlatform from './pages/tradingPlatform/TradingPlatform';
import Authenticate from './pages/Authenticate';
import PlayersLobby from './pages/playersLobby/PlayersLobby';
import { RoomContext } from './contexts/RoomProvider';
import { fetchJoinedPlayers } from './hooks/useGetJoinedPlayersByRoomId';

const Landing = ({ children }) => {
  const { setRoom, setPlayers } = useContext(RoomContext);
  const { roomId } = useParams();

  useEffect(() => {
    const getJoinedPlayers = async () => {
      const data = await fetchJoinedPlayers(roomId);

      setPlayers(data.players);
      setRoom(data.room);
    };

    getJoinedPlayers();
  }, [roomId]);

  return <>{children}</>;
};

const Routing = () => {
  const showHeader = !window.location.pathname.includes('lobby');

  return (
    <div className="Routing">
      <Router>
        {showHeader && <Header />}
        <Routes>
          <Route
            path="/room/:roomId/authenticate"
            element={
              <Landing>
                <Authenticate />
              </Landing>
            }
          />
          <Route
            path="/room/:roomId/lobby"
            element={
              <Landing>
                <PlayersLobby />
              </Landing>
            }
          />
          <Route
            path="/room/:roomId"
            element={
              <Landing>
                <Home />{' '}
              </Landing>
            }
          >
            <Route
              path="leader-board"
              element={
                <Landing>
                  <LeaderBoard />
                </Landing>
              }
            />
            <Route
              path="orders"
              element={
                <Landing>
                  <Orders />
                </Landing>
              }
            />
            <Route
              path="positions"
              element={
                <Landing>
                  <Positions />
                </Landing>
              }
            />
          </Route>
          <Route
            path="/room/:roomId/chart"
            element={
              <Landing>
                <TradingPlatform />
              </Landing>
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default Routing;
