import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface LoadingProps {
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({ color = '#d43725' }) => {
  return (
    <Box sx={{ display: 'flex', minWidth: 650 }}>
      <CircularProgress
        sx={{
          color: color
        }}
      />
    </Box>
  );
};

export default Loading;
