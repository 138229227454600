import React, { useEffect, useState, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { PlayerContext } from '../../contexts/PlayerProvider';
import { getRoomPlayer } from '../../hooks/useGetRoomPlayerDetails';
import { useCountdownTimer } from '../../hooks/common/useCountdownTimer';
import { RoomContext } from '../../contexts/RoomProvider';
const pages = ['Trade', 'Orders', 'Positions', 'Leader Board'];

function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElInfo, setAnchorElInfo] = useState(null);
  const { player, setPlayer } = useContext(PlayerContext);
  const { room } = useContext(RoomContext);

  const { countdown, isCountdownFinished } = useCountdownTimer(new Date(room.endDateTime));

  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoomPlayer = async () => {
      try {
        const roomPlayer = await getRoomPlayer();
        setPlayer(roomPlayer);
      } catch (error) {
        // Handle error
        console.error('Error fetching room player:', error);
        if (error?.response) {
          alert(error.response.data.message);
        } else {
          alert('Internal server error');
        }
      }
    };

    fetchRoomPlayer();
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    handleNavigate(page);
  };

  const handleOpenInfoMenu = (event) => {
    setAnchorElInfo(event.currentTarget);
  };

  const handleCloseInfoMenu = () => {
    setAnchorElInfo(null);
  };

  const handleNavigate = (page) => {
    const pages = {
      Trade: `/room/${room._id}/chart`,
      'Leader Board': `/room/${room._id}/leader-board`,
      Orders: `/room/${room._id}/orders`,
      Positions: `/room/${room._id}/positions`,
      Holdings: `/room/${room._id}/holdings`
    };

    const pagePath = pages[page];
    if (pagePath) navigate(pagePath);
  };

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href={`/room/${room._id}/lobby`}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'Monoton',
                fontWeight: 500,
                letterSpacing: '.3rem',
                color: '#D43725',
                textDecoration: 'none'
              }}
            >
              TSA
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' }
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'Monoton',
                fontWeight: 500,
                letterSpacing: '.3rem',
                color: '#D43725',
                textDecoration: 'none'
              }}
            >
              TSA
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                paddingLeft: '2rem'
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handleNavigate(page)}
                  sx={{
                    my: 2,
                    color: '#000',
                    display: 'block',
                    pl: '1rem',
                    fontWeight: 500
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={'center'}
              flexDirection={'column'}
              sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}
            >
              <Box display={{ xs: 'none', md: 'flex' }} alignItems="center" gap="0.5rem">
                <PersonIcon />
                <Typography sx={{ fontWeight: 'bold' }}>{player?.data?.userId?.fullName || 'N/A'}</Typography>
              </Box>
              <Typography>
                {!!room && isCountdownFinished ? (
                  <span style={{ fontWeight: 'bold' }}>{room.name} Ended</span>
                ) : (
                  <>
                    Ends in: <span style={{ fontWeight: 'bold' }}>{countdown}</span>
                  </>
                )}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" flexDirection={'column'} sx={{ marginLeft: '0.5rem' }}>
              <Typography fontSize={'0.8rem'}>Available Funds:</Typography>
              <Typography sx={{ fontWeight: 'bold' }}>{player?.data?.availableFunds?.toFixed(2) || '0.00'}</Typography>
            </Box>
            <IconButton
              size="large"
              aria-label="info"
              aria-controls="info-menu"
              aria-haspopup="true"
              onClick={handleOpenInfoMenu}
              color="inherit"
              sx={{ display: { xs: 'flex', md: 'none' } }}
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="info-menu"
              anchorEl={anchorElInfo}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElInfo)}
              onClose={handleCloseInfoMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              <MenuItem>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <PersonIcon />
                  <Typography sx={{ fontWeight: 'bold' }}>{player?.data?.userId?.fullName || 'N/A'}</Typography>
                </Box>
              </MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;
